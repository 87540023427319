header {
  .top {
    background:#000;
    color:#fff;
    padding:20px 0;
    vertical-align: top;

    ul {
      display: inline-block;
      float: right;
      margin-top: 23px;
      @media(max-width:$tablet) {
        margin-top: 15px;
      }
      @media(max-width:$mobile-nav) {
        display: none;
      }
      li {
        display: inline-block;
        vertical-align: top;
        text-align:left;
        margin-left: 15px;
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          margin-left: 0px;
        }
        a {
          color: white;
          font-family: $secondary-font-family;
          font-weight: normal;
          text-transform: uppercase;
          font-size: 18px;
          @media(max-width:$tablet) {
            font-size: 16px;
          }
          &:hover {
            text-decoration: none;
            border-bottom: 2px solid white;
          }
        }
      }
    }
  }
  .btm {
    background: $bl-yellow;
    padding: 9px 0 7px 0;
    @media(max-width:640px) {
      padding: 5px 0 3px 0;
    }
    h4, h5 {
      font-family: $secondary-font-family;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
    }
    h4 {
      float: left;
      @media(max-width:$mobile-nav) {
        display: none;
      }
    }
    h5 {
      float: right;
      a {
        color: black;
        text-decoration: underline;
      }
      @media(max-width:$mobile-nav) {
        float: none;
        text-align: center;
        font-size: 16px;
      }
      @media(max-width: 640px) {
        font-size: 14px;
      }
    }
  }
}

#logo {
 display: inline-block;
 max-width: 240px;
 @media(max-width:$tablet) {
   max-width: 180px;
 }

 @media(max-width:$mobile-nav) {
   max-width: 150px;
 }
}

/* Navigation Button */
#mobile-burger-btn {
  display:none;
  width: 40px;
  height: 40px;
  margin:0;
  cursor: pointer;
  background:#000;
  z-index: 100;
  text-align:center;
  margin-top:15px;
  transition: right 0.5s;
  i {
    font-size:28px;
    position: relative;
    top:5px;
    &:nth-child(2) {
      display:none;
    }
  }
  @media(max-width:$mobile-nav) {
    display: inline-block;
    position: absolute;
    top:5px;
    right:15px;
    z-index: 9999;

  }
  &.menu-open {
    right:20px;
    i {
      &:nth-child(1) { display: none; }
      &:nth-child(2) { display: inline-block; }
    }
  }
}
