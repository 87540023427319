
$primary-font-family:	"abolition";
$secondary-font-family: "alternate-gothic-no-3-d";
$standard-font-family: "proxima-nova-condensed";

$container: 1094;

$red: #FD0000;
$green: #02C72D;

$break-small: 400px;
$break-large: 1130px;
$tablet: 1080px;
$mobile-nav: 940px;

$bl-yellow: #F8E01C;
$bl-red: #BB1D26;
$bl-grey: #1D1D1D;
$bl-btn-grey: #323232;
$bl-header: #000000;
$bl-sub: #8F8F8F;
$bl-sub-black: #AAAAAA;
$bl-body: #9C9C9C;
$bl-light-grey: #F9F9F9;
$bl-grey-border: #E9E9E9;
