html {
  box-sizing: border-box;
}

body {
  font:16px/1.5em $standard-font-family;
  position: relative;
  background:#000;
  @media (max-width:900px) { font-size:15px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:14px; line-height: 1.5em; }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight:700;
}


fieldset {
  border:0;
  margin:0;
  padding:0;
}

img {
  max-width:100%;
}

.center { text-align:center; }
.clear { clear:both; }


#page-wrap {
  position: relative;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: right 0.3s ease;
  background: $bl-light-grey;
  min-height: 100vh;
  a {
    position: relative;
    &:active {
      top:1px;
    }
  }
}

#productFeatureLoader  { display:none; }


#loader {
  display:block;
  margin:35px auto;
}

#zero {
  display:none;
  text-align:center;
  color:#fff;
  padding:35px 0;
}

.default-pointer {
  cursor: default;
}

.hide-from-mobile {
  display: block;
  @media(max-width:900px) {
    display:none;
  }
}

.show-only-on-mobile {
  display: none;
  @media(max-width:900px) {
    clear:both;
    display:inline-block;
  }
}

.hr {
  border-top:1px #D4CFCF solid;
}

.preloader {
  background-color:rgba(#000, 0.7);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  margin-top: 0px;
  top: 0px;
  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
  #loading-center{
  	width: 100%;
  	height: 100%;
  	position: relative;
  	}
  #loading-center-absolute {
  	position: absolute;
  	left: 50%;
  	top: 50%;
  	height: 60px;
  	width: 60px;
  	margin-top: -30px;
  	margin-left: -30px;
    -webkit-animation: loading-center-absolute 1s infinite;
  	animation: loading-center-absolute 1s infinite;
  }
  .object{
  	width: 20px;
  	height: 20px;
  	background-color: #FFF;
  	float: left;
  	-moz-border-radius: 50% 50% 50% 50%;
  	-webkit-border-radius: 50% 50% 50% 50%;
  	border-radius: 50% 50% 50% 50%;
  	margin-right: 20px;
  	margin-bottom: 20px;
  }
  .object:nth-child(2n+0) {
  	margin-right: 0px;

  }

}

#object_one{
	-webkit-animation: object_one 1s infinite;
	animation: object_one 1s infinite;
	}
#object_two{
	-webkit-animation: object_two 1s infinite;
	animation: object_two 1s infinite;
	}
#object_three{
	-webkit-animation: object_three 1s infinite;
	animation: object_three 1s infinite;
	}
#object_four{
	-webkit-animation: object_four 1s infinite;
	animation: object_four 1s infinite;
	}

@-webkit-keyframes loading-center-absolute{
100% {
    -ms-transform: rotate(360deg);
   	-webkit-transform: rotate(360deg);
    transform: rotate(360deg);
	}

}
@keyframes loading-center-absolute{
100% {
    -ms-transform: rotate(360deg);
   	-webkit-transform: rotate(360deg);
    transform: rotate(360deg);
	}
}




@-webkit-keyframes object_one{
50% {
    -ms-transform: translate(20px,20px);
   	-webkit-transform: translate(20px,20px);
    transform: translate(20px,20px);
	}
}
@keyframes object_one{
50% {
     -ms-transform: translate(20px,20px);
   	-webkit-transform: translate(20px,20px);
    transform: translate(20px,20px);
	}
}


@-webkit-keyframes object_two{
50% {
    -ms-transform: translate(-20px,20px);
   	-webkit-transform: translate(-20px,20px);
    transform: translate(-20px,20px);
	}
}
@keyframes object_two{
50% {
    -ms-transform: translate(-20px,20px);
   	-webkit-transform: translate(-20px,20px);
    transform: translate(-20px,20px);
	}
}



@-webkit-keyframes object_three{
50% {
    -ms-transform: translate(20px,-20px);
   	-webkit-transform: translate(20px,-20px);
    transform: translate(20px,-20px);
	}
}
@keyframes object_three{
50% {
    -ms-transform: translate(20px,-20px);
   	-webkit-transform: translate(20px,-20px);
    transform: translate(20px,-20px);
	}
}



@-webkit-keyframes object_four{
50% {
    -ms-transform: translate(-20px,-20px);
   	-webkit-transform: translate(-20px,-20px);
    transform: translate(-20px,-20px);
	}
}
@keyframes object_four{
50% {
    -ms-transform: translate(-20px,-20px);
   	-webkit-transform: translate(-20px,-20px);
    transform: translate(-20px,-20px);
	}
}

.full-screen-img {
  max-width:90%;
}
