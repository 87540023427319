h1 {
  font-size:26px;
  line-height:1.5em;
  margin:0;
  font-weight: 100;
  font-family: $primary-font-family;
  @media (max-width:900px) { font-size:22px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:20px; line-height: 1.5em; }
  @media (max-width:450px) { font-size:18px; line-height: 1.5em; }
}


@font-face {
    font-family: 'webfontregular';
    src: url('../fonts/uknumberplate-webfont.woff2') format('woff2'),
         url('../fonts/uknumberplate-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
