button {
  border:0;
}
.btn {
  border:0;
  cursor: pointer;
  display:inline-block;
  position:relative;
  font-size: 18px;
  line-height: 18px;
  font-family: $secondary-font-family;
  text-transform: uppercase;
  font-weight: normal;
  padding: 15px 20px 15px 20px;
  border-radius: 3px;
  &:active {
    top:1px;
  }
  &:hover {
    text-decoration: none;
    color: #FFFFFF;
  }
  &.yellow {
    background: $bl-yellow;
    color: black;
  }
  &.red {
    background: $bl-red;
    color: white;
  }
  &.grey {
    background: $bl-grey;
    color: white;
  }
  .fa {
    margin-left: 15px;
  }
}

.back-btn {
  display:inline-block;

}

.main-content {
  .btn-wrap {
    a.ebay {
      background: #FF1000;
      border: 1px solid #FF1000;
      color:#fff;
      margin-right:15px;
    }
  }
}

/* Social Media */
.social-items {
  margin:25px 0;
  display: block;
  font-family:"proxima-nova";
  background:#549130;
  padding:15px 25px;
  color:#fff;
  vertical-align:top;
  span {
    vertical-align: top;
    font-weight:500;
    display: inline-block;
    font-size:20px;
    margin-top:7px;
    @media(max-width:900px) { font-size:18px; }
    @media(max-width:600px) { font-size:17px;  }
    @media(max-width:450px) { font-size:15px; }
  }
  a {
    color:#1F4D64;
    margin-left:20px;
    display:inline-block;
    i {
      font-size:34px;
      color:#fff;
      @media(max-width:900px) { font-size:30px; }
      @media(max-width:600px) { font-size:28px;  }
      @media(max-width:450px) { font-size:26px; }
    }
  }
}
