.map {
  height:525px;
  margin:0;
  width:100%;
  display: block;
  .infoMarker {
    text-align:left;
    width:215px;
    h4 {
      font-size: 28px;
      line-height: 1.5em;
      margin: 0;
      font-weight: 100;
      font-family: "abolition";
    }
    p {
      font-size: 19px;
      line-height: 1.3em;
      color: #8F8F8F;
      font-family: "alternate-gothic-no-3-d";
      margin:15px 0;
    }
    a.btn {

    }
  }
}
