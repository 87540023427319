.contact-form {
  max-width:640px;
  margin:75px auto;
  form {
    label {
      display: block;
      margin:0 0 5px;
      font-size: 18px;
      color: #000000;
      text-transform: uppercase;
      font-family:$secondary-font-family;
      font-weight:400;
    }
    .select_style {
      margin:0 0 35px;
    }
    input {
      margin:0 0 35px;

    }
    textarea {

    }
  }
}

.tel {
  font-weight: normal;
  color: #555;
  font-style: normal;
}
