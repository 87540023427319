footer {
  background:black;
  color:white;
  position: relative;
  overflow: hidden;
  .layer-overlay {
    display: block;
    width:100%;
    height:2000px;
    position: absolute;
    z-index: 100;
    top:0;
    background:#191919;
    left:5%;
    //transform: rotate(25deg);
    transform: skewX(-30deg);
  }
  .top {
    padding:60px 0 40px;
    vertical-align: top;
    position: relative;
    z-index: 200;
    .col {
      display: inline-block;
      vertical-align: top;
      &:nth-child(1) {
        width:371px;
        @media(max-width:1310px) {
          width:100%;
          margin-bottom:50px;
        }
      }
      &:nth-child(2) {
        width:260px;
        margin:0 90px;
        @media(max-width:1310px) {
          width:calc(50% - 10px);
          margin-left:0;
          margin-right:10px;
        }
        @media(max-width:900px) {
          width:100%;
          margin-right:0;
          margin-bottom:50px;
        }
      }
      &:nth-child(3) {
        width:362px;
        @media(max-width:1310px) {
          width:calc(50% - 10px);
          margin-left:10px;
          margin-right:0;
        }
        @media(max-width:900px) {
          width:100%;
          margin-left:0;
        }
      }
      p {
        color:#A9A9A9;
        font-size: 15px;
        color: #A9A9A9;
        line-height: 25px;
        margin:0 0 40px;
        font-weight:300;
      }
      img {
        margin:0 0 40px;
      }
      h3 {
        font-size: 19px;
        color: #A7A7A7;
        font-weight:700;
        text-transform: uppercase;
        margin:0 0 40px;
      }
      ul.sitemap {
        column-count: 2;
        @media(max-width:400px) {
          column-count: 1;
        }
        li {
          &:before {
            content: "•";
            color:$green;
            font-size:28px;
            margin-right:6px;
            display: inline-block;
            position: relative;
            top:2px;
          }
          a {
            color:#A7A7A7;
          }
        }
      }
      ul.contact {
        li {
          margin-bottom:17px;
          .ico-wrap {
            vertical-align: top;
          }
          .txt-wrap {
            width:calc(100% - 41px);
            display: inline-block;
            vertical-align: top;
            font-size:15px;
            color:#D4D2D5;
            padding-left:18px;
            h4 {
              color:$green;
              line-height: 1em;
              margin:0;
              text-transform: uppercase;
            }
            a {
              color:#D4D2D5;
            }
          }
        }
      }

    }
  }
  .btm {
    position: relative;
    z-index: 300;
    font-size:15px;
    font-weight:300;
    padding: 20px 0;
    .col-half {
      display: inline-block;
      width:75%;
      font-size: 13px;
      @media(max-width:600px) {
        text-align: center;
      }
      span {
        color: $bl-yellow;
        font-family: $secondary-font-family;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 15px;
        @media(max-width:600px) {
          display: block;
        }
      }
      em {
        @media(max-width:600px) {
          display: none;
        }
      }
      a {
        color: white;
        border-bottom: 1px solid white;
        &:hover {
          text-decoration: none;
          color: #A3A3A3;
          border-bottom: 1px solid #a3a3a3;
        }
      }
      &:nth-child(2) {
        text-align:right;
        width:25%;
        @media(max-width:600px) {
          text-align: center;
          width: 100%;
          margin-top: 10px;
        }
        a {
          border-bottom: none;
          font-size: 28px;
          line-height: 28px;
          position: relative;
          top: 4px;
          display: inline-block;
          margin-left: 10px;
          &:hover {
            border-bottom: none;
          }
        }
      }
      @media(max-width:600px) {
        width:100%;
        text-align:center;
        &:nth-child(2) {
          text-align:center;
        }
      }
    }
  }
}

.cta {
  background: $bl-grey;
  text-align: center;
  padding: 80px 0;
  h1 {
    font-size: 52px;
    line-height: 52px;
    font-weight: 100;
    color: $bl-yellow;
    margin-bottom: 10px;
    @media(max-width: $mobile-nav) {
      font-size: 46px;
      line-height: 46px;
    }
    @media(max-width: 600px) {
      font-size: 40px;
      line-height: 40px;
    }
  }
  h2, h3 {
    color: white;
    font-family: $secondary-font-family;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 30px;
    @media(max-width: $mobile-nav) {
      font-size: 20px;
      line-height: 24px;
    }
    @media(max-width: 600px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  a {
    color: $bl-yellow;
  }
}
