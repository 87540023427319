.slideshow {
  position: relative;
  height:525px;
  @media(max-width: $mobile-nav) {
    height: 400px;
  }
  @media(max-width: 600px) {
    height: 250px;
  }
  .cycle-slideshow {
    position: relative;
    z-index: 100;
    .slide {
      width:100%;
      height:525px;
      @media(max-width: $mobile-nav) {
        height: 400px;
      }
      @media(max-width: 600px) {
        height: 250px;
      }
      background-repeat:no-repeat;
      background-size:cover;
      background-position:center;
      .inner-wrap {
        display:flex;
        align-items:center;
        height:525px;
        @media(max-width: $mobile-nav) {
          height: 400px;
        }
        @media(max-width: 600px) {
          height: 250px;
        }
      }
      .txt-wrap {
        h2 {
          font-size: 74px;
          color: #FFFFFF;
          letter-spacing: 0;
          font-family: "abolition";
          font-weight: 100;
          line-height: 1.35em;
          @media(max-width: $mobile-nav) { font-size: 60px; }
          @media(max-width: 600px) { font-size: 40px; }
        }
        p {
          font-size: 24px;
          color: rgba(255,255,255,0.63);
          letter-spacing: 0;
          line-height: 18px;
          font-family: "alternate-gothic-no-3-d";
          text-transform: uppercase;
          font-weight: normal;
          font-weight:1.35em;
          margin:0 0 30px;
          font-weight:400;
          @media(max-width: $mobile-nav) { font-size: 18px; }
          @media(max-width: 600px) { font-size: 14px; margin: 0 0 20px;}
        }
        a.btn {

        }
      }
      .btn-wrap {
        margin:25px 0;
        display: block;
          display:flex;
          align-items:center;
        img {
          margin-left:20px;
          display: inline-block;
          &:nth-child(1) {
            margin-left:0;
            height:31px;
          }
          &:nth-child(2) {
            height:29px;
          }
          &:nth-child(3) {
            height:24px;
          }
        }
      }
      .img-wrap {
        display:none;
      }
    }
  }
  .banner-paging-wrap {
    position: absolute;
    bottom:25px;
    left:0;
    width:100%;
    height:12px;
    z-index: 200;
    .inner-wrap {
      text-align:center;
    }
    span {
      width:12px;
      height:12px;
      border-radius:50%;
      display: inline-block;
      margin:0 4px;
      background:rgba(#FFFFFF, 0.5);
      text-indent: 999999px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      &:active {
        top:1px;
      }
      &.cycle-pager-active {
        background:#F8E01C;
      }
    }
  }
}
