.inner-wrap {
  max-width:$container + px;
  width:90%;
  margin:auto;
}

.general-items {
  display: block;
  margin:0 auto;
  li {
    display: inline-block;
    margin-bottom:15px;
    @media(min-width:900px) {
      width:calc(33.3333% - 10px);
      &:nth-child(3n+2) {
        margin-left:15px;
        margin-right:15px;
      }
    }
    @media(min-width:650px) and (max-width:900px) {
      width:calc(50% - 10px);
      &:nth-child(odd) {
        margin-left:0;
        margin-right:10px;
      }
      &:nth-child(even) {
        margin-left:10px;
        margin-right:0;
      }
    }
    @media(max-width:650px) {
      width:100%;
      display: block;
      margin-bottom:15px;
    }
    a {
      display:block;
      width:100%;
      height:284px;
      position: relative;
      background-size:cover;
      background-position: center;
      background-repeat: no-repeat;
      .overlay {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:284px;
        background:rgba(#1D1D1D,0.7);
      }
      .title {
        padding:20px;
        background:$green;
        display: inline-block;
        color:#fff;
        position: relative;
        z-index: 100;
        font-size:19px;
        text-transform: uppercase;
        font-weight:700;
        max-width:calc(100% - 45px);
        span.inner {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display:block;
          max-width:calc(100%);
        }
        &:after {
          position: absolute;
          top: 0;
          right: -35px;
          border-right: 35px solid transparent;
          border-top: 64px solid #02C72D;
          width: 0;
          content: " ";
        }
      }
      .btn {
        position: absolute;
        left:20px;
        bottom:20px;
        z-index: 100;
        &:active {
          top:auto;
          bottom:21px;
        }
      }
    }
  }
}

.columns {
  column-count: 3;
  column-gap: 15px;
  height:620px;
  @media(max-width:900px) {
    column-count: 2;
    height:auto;
  }
  @media(max-width:650px) {
    column-count: 1;
    height:auto;
  }
  li {
    width:100%;
    @media(min-width:900px) {
      &:nth-child(3n+2) {
        margin-left:0;
        margin-right:0;
      }
      &:nth-child(3) {
        a {
          height:582px;
          @media(max-width:900px) {
            height:284px;
          }
          .overlay {
            height:582px;
            @media(max-width:900px) {
              height:284px;
            }
          }
        }
      }
    }
    @media(min-width:650px) and (max-width:900px) {
      &:nth-child(odd) {
        margin-left:0;
        margin-right:0;
      }
      &:nth-child(even) {
        margin-left:0;
        margin-right:0;
      }
    }
    @media(max-width:650px) {
      width:100%;
      display: block;
      margin-bottom:15px;
    }
  }
}


.sub-menu-wrap {
  width:330px;
  margin-right:70px;
  display: inline-block;
  vertical-align: top;
  @media(max-width:1000px) {
    display: none;
  }
  h3 {
    display: block;
    padding:20px 30px;
    line-height: 1em;
    color:$green;
    background:#000;
    font-weight:600;
    font-size:22px;
  }
  ul {
    background:#E7E4E7;
    padding:10px 30px 20px;
    margin:0 0 20px;
    li {
      a {
        color:#666;
        border-top:1px #fff solid;
        line-height:50px;
        height:50px;
        display:block;
        font-size:19px;
      }
      &:first-child {
        a { border-top:0; }
      }
    }
  }
  .social {
    margin:10px 0;
    background:blue;
    color:#fff;
    display:block;
    @media(max-width:1000px) {
      display:none;
    }
    vertical-align: middle;
    &[href*="facebook"] { background:#2B5B9B; }
    &[href*="youtube"] { background:#F13119;}
    &[href*="twitter"] { background:#2FAEEE; }
    &[href*="flickr"] { background:#F92980; }
    .social-ico-wrap {
      width:60px;
      border-right:1px #fff solid;
      display: inline-block;
      color:#fff;
      height:58px;
      line-height: 58px;
      border-right:1px #fff solid;
      text-align:center;
      vertical-align: middle;
      i {
        font-size:26px;
        line-height: 1em;
        vertical-align: middle
      }
    }
    .txt-wrap {
      width:calc(100% - 61px);
      display: inline-block;
      color:#fff;
      height:58px;
      line-height: 58px;
      padding-left:20px;
      vertical-align: middle;
      font-size:19px;
    }
  }
}

.mobile-sub-menu-wrap {
  display: none;
  @media(max-width:1000px) {
    display: block;
  }
}

.main-content {
  .sub-page-txt {
    max-width:780px;
    display: inline-block;
    width:calc(100% - 400px);
    @media(max-width:1000px) {
      width:100%;
      max-width:none;
    }
    h1 {
      text-transform: capitalize;
      margin:10 0 40px;
      font-size:29px;
      &.news-title {
        margin:0 0 10px;
      }
    }
    .general {
      p {
        margin:0 0 1.5em;
        &:first-child {
          font-size:26px;
          line-height: 1.26em;
          @media(max-width:900px) { font-size:22px; line-height:1.26em; }
          @media(max-width:600px) { font-size:20px; line-height:1.26em; }
          @media(max-width:450px) { font-size:17px; line-height:1.26em; }
        }
      }
    }
    ul.lists {
      clear:both;
      margin:65px 0;
      li {
        margin-top:30px;
        padding-top:30px;
        border-top:4px solid #EAE6E6;
        &:first-child {
          margin-top:0;
          padding-top:0;
          border-top:0;
        }
        .img-wrap {
          height:180px;
          width:256px;
          margin-right: 30px;
          display:inline-block;
          vertical-align: top;
          @media(max-width:650px) {
            width:100%;
            margin-right:0;
          }
          .inner {
            width:100%;
            height:180px;
            display:flex;
            justify-content:center;
            align-items:center;
            img {

            }
          }
        }
        .txt-wrap {
          width:calc(100% - 290px);
          display:inline-block;
          vertical-align: top;
          @media(max-width:650px) {
            width:100%;
          }
          h3 {
            font-size:29px;
            font-weight:700;
            color:#0F0F0F;
            @media(max-width:900px) { font-size:26px; line-height:1.5em; }
            @media(max-width:600px) { font-size:22px; line-height:1.5em; }
            @media(max-width:450px) { font-size:20px; line-height:1.5em; }
          }
          p {
            color:#666;
            font-size:18px;
            margin:20px 0;
            @media(max-width:900px) { font-size:17px; line-height:1.5em; }
            @media(max-width:600px) { font-size:16px; line-height:1.5em; }
            @media(max-width:450px) { font-size:15px; line-height:1.5em; }
          }
          a.btn {
            background:$green;
            color:#fff;
            border:0;
            margin:0;
            text-transform: capitalize;
            font-size:19px;
            @media(max-width:900px) { font-size:18px; line-height:1.5em; }
            @media(max-width:600px) { font-size:17px; line-height:1.5em; }
            @media(max-width:450px) { font-size:16px; line-height:1.5em; }
          }
        }
      }
    }
  }
}

.listQuestions {
  margin-bottom:75px;
  ol {
    li {
      span {
        display: inline-block;
        width:22px;
      }
      a {
        color:#000;
      }
    }
  }
}

.listQuestionsAnswers {
  .item {
    margin-bottom:35px;
    h3 {
      font-weight:600;
      font-size:19px;
      @media(max-width:900px) { font-size:18px; }
      @media(max-width:600px) { font-size:17px; }
      @media(max-width:450px) { font-size:16px; }
    }
    div {
      font-size:17px;
      @media(max-width:900px) { font-size:16px; }
      @media(max-width:600px) { font-size:15px; }
      @media(max-width:450px) { font-size:14px; }
    }
  }
}

ul#logo-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 50px 0;
  @media(max-width: $mobile-nav) {
    margin: 20px 0;
  }
  li {
    width: calc(33.33333% - 10px);
    margin-right: 15px;
    margin-bottom: 15px;
    height: 200px;
    background: white;
    border: 1px solid $bl-grey-border;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(3n) {
      margin-right: 0;
    }
    @media(max-width: $mobile-nav) {
      width: calc(50% - 10px);
      height: 160px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:nth-child(3n) {
        margin-right: 15px;
      }
      &:nth-child(6n) {
        margin-right: 0;
      }
    }
    @media(max-width: 600px) {
      height: 100px;
    }
    a {
      display: block;
      width: 100%;
      a {
        display: inline-block;
        width:auto;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      max-width: 90%;
      @media(max-width: $mobile-nav) {
        max-width: 80%;
        height: auto;
      }
      @media(max-width: 600px) {
        max-width: 75%;
        max-height: 75%;
      }
      &.d2 {
        @media(max-width: 600px) {
          max-height: 50%;
        }
      }
      &.fitting {
        @media(max-width: 600px) {
          max-height: 90%;
        }
      }
      &.racingline-performance {
        max-width: 70%;
        @media(max-width: 600px) {
          max-width: 80%;
        }
      }
      &.kenwood, &.hertz, &.rainbow, &.clifford, &.focal {
        max-width: 70%;
        @media(max-width: 600px) {
          max-width: 70%;
        }
      }
      &.jl-audio, &.rockford-fosgate, &.alpine, &.thinkware, &.blackvue {
        max-width: 80%;
        @media(max-width: 600px) {
          max-width: 70%;
        }
      }
      &.autowatch-ghost, &.audison {
        max-width: 60%;
        @media(max-width: 600px) {
          max-width: 60%;
        }
      }
    }
    .desc {
      display:none;
    }
  }
}

.page-intro {
  padding: 30px 0 0 0;
  .inner-wrap {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;
  }
  &.white {
    background: white;
    padding: 30px 0 60px 0;
  }
  &.black {
    background: black;
    padding: 30px 0 60px 0;
    .text {
      h1 {
        color: white;
      }
    }
  }
  .text {
    width: 30%;
    @media(max-width: $mobile-nav) {
      width: 40%;
      padding: 30px 0;
    }
    @media(max-width: 600px) {
      width: 100%;
      padding: 30px 0 0 0;
    }
    h1 {
      font-size: 82px;
      word-spacing: 2000px;
      line-height: 74px;
      color: black;
      font-weight: 100;
      @media(max-width: $mobile-nav) {
        font-size: 64px;
        line-height: 60px;
      }
      @media(max-width: 600px) {
        font-size: 40px;
        line-height: 44px;
        word-spacing: normal;
        text-transform: uppercase;
        letter-spacing: -1px;
      }
    }

    h2 {
      font-size: 24px;
      color: $bl-sub-black;
      font-family: $secondary-font-family;
      font-weight: 100;
      margin: 30px 0;
      @media(max-width: $mobile-nav) {
        font-size: 20px;
        margin: 10px 0 30px 0;
      }
    }

    img {
      height: 48px;
    }

    .btn {
      @media(max-width: 600px) {
        width: 100%;
        .fa-caret-down {
          float: right;
        }
      }
    }
  }
  .image {
    width: 65%;
    margin-left: 5%;
    @media(max-width: $mobile-nav) {
      width: 55%;
    }
    @media(max-width: 600px) {
      width: 100%;
      margin-left: 0%;
    }
    img {
      max-height: 90%;
    }
  }
}


.canwebagit {
  background: repeating-linear-gradient(135deg, #111, #111 20px, #000 20px, #000 40px);
  padding: 50px 0;
  .inner-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .title {
    width: 34%;
    @media(max-width: $mobile-nav) {
      width: 25%;
    }
    @media(max-width: 750px) {
      width: 100%;
      text-align: center;
    }
  }
  h1 {
    color: $bl-yellow;
    font-size: 66px;
    line-height: 60px;
    @media(max-width: $mobile-nav) {
      font-size: 40px;
      line-height: 40px;
    }
    @media(max-width: 750px) {
      display: inline-block;
      margin: 0 3px;
    }
  }
  h2 {
    color: $bl-sub-black;
    font-family: $secondary-font-family;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 10px;
    @media(max-width: $mobile-nav) {
      font-size: 18px;
      margin-bottom: 5px;
    }
    @media(max-width: 750px) {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  a {
    color: $bl-yellow;
  }
  .bagitform {
    width: 66%;
    @media(max-width: $mobile-nav) {
      width: 75%;
    }
    @media(max-width: 750px) {
      width: 100%;
      text-align: center;
    }
    fieldset {
      .select_style {
        width: calc(50% - 80px);
        margin-right: 10px;
        @media(max-width: 600px) {
          width: 100%;
          margin: 5px 0;
        }
      }
      button {
        display: inline-block;
        font-weight: 100;
        width: 140px;
        vertical-align: top;
        @media(max-width: 600px) {
          width: 100%;
          margin: 5px 0;
        }
      }
    }
  }

}

.canwebagit {
  background: repeating-linear-gradient(135deg, #111, #111 20px, #000 20px, #000 40px);
  padding: 50px 0;
  .inner-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .title {
    width: 34%;
    @media(max-width: $mobile-nav) {
      width: 25%;
    }
    @media(max-width: 750px) {
      width: 100%;
      text-align: center;
    }
  }
  h1 {
    color: $bl-yellow;
    font-size: 66px;
    line-height: 60px;
    @media(max-width: $mobile-nav) {
      font-size: 40px;
      line-height: 40px;
    }
    @media(max-width: 750px) {
      display: inline-block;
      margin: 0 3px;
    }
  }
  h2 {
    color: $bl-sub-black;
    font-family: $secondary-font-family;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 10px;
    @media(max-width: $mobile-nav) {
      font-size: 18px;
      margin-bottom: 5px;
    }
    @media(max-width: 750px) {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .bagitform {
    width: 66%;
    @media(max-width: $mobile-nav) {
      width: 75%;
    }
    @media(max-width: 750px) {
      width: 100%;
      text-align: center;
    }
    fieldset {
      .select_style {
        width: calc(50% - 80px);
        margin-right: 10px;
        @media(max-width: 600px) {
          width: 100%;
          margin: 5px 0;
        }
      }
      button {
        display: inline-block;
        font-weight: 100;
        width: 140px;
        vertical-align: top;
        @media(max-width: 600px) {
          width: 100%;
          margin: 5px 0;
        }
      }
    }
  }
  .canwebagit-response {
    text-align:center;
    width:100%;
    clear: both;
    h1 {
      margin-bottom:30px;
    }
    p {
      font-size: 24px;
      color: rgba(255,255,255,0.63);
      letter-spacing: 0;
      line-height: 27px;
      text-transform: uppercase;
      font-family: $secondary-font-family;
      font-weight: 100;
      @media(max-width: $mobile-nav) { font-size: 24px; }
      @media(max-width: 600px) { font-size: 20px; }
    }
    h2 {
      font-size: 28px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 35px;
      @media(max-width: $mobile-nav) { font-size: 24px; }
      @media(max-width: 600px) { font-size: 20px; }
    }
    .img-wrap {
      margin:35px 0;
      img {
        margin:0 20px;
        height: 50px;
        @media(max-width: $mobile-nav) { height: 40px; margin: 0 10px;}
        @media(max-width: 600px) { height: 30px; margin: 0 5px; }
      }
      img#bag-airlift-performance {
        height: 70px;
        @media(max-width: $mobile-nav) { height: 50px; margin: 0 10px;}
        @media(max-width: 600px) { height: 40px; margin: 0 10px; }
      }
    }
    .white {
      color:white;
    }
  }
}


#airlift-management {
  padding: 60px 0;
  @media(max-width: $mobile-nav) {
    padding: 40px 0;
  }
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    li {
      width: calc(33.3333333% - 10px);
      border: 1px solid $bl-grey-border;
      background: black;
      margin-left: 15px;
      @media(max-width: $mobile-nav) {
        width: calc(50% - 5px);
        margin-left: 10px;
        margin-bottom: 10px;
        &:nth-child(3) {
          margin-left: 0;
        }
      }
      @media(max-width: 600px) {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 30px;
      }
      &:nth-child(1) {
        margin-left: 0;
      }
      .desc {
        padding: 50px 30px;
        text-align: center;
        position: relative;
        top: -1px;
        background: black;
        h1 {
          display: none;
        }
        h2 {
          color: white;
          text-transform: uppercase;
          font-size: 22px;
          font-family: $secondary-font-family;
          font-weight: 100;
          margin: 15px 0 20px 0;
        }
        p {
          color: white;
          font-size: 15px;
          margin: 0;
          line-height: 18px;
        }
      }
      img.airlift-v2 {
        margin: 6px 0;
      }
    }
  }
  .airlift-apps {
    text-align: center;
    color: $bl-body;
    text-transform: uppercase;
    font-family: $secondary-font-family;
    font-weight: 100;
    margin-top: 60px;
    margin-bottom: 10px;
    @media(max-width: $mobile-nav) {
      margin-top: 40px;
    }
    @media(max-width: 600px) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    img {
      margin: 10px 5px 0 5px;
    }
  }
}

.tuning {
  background: black url("../imgs/tuning.jpg") no-repeat;
  background-size: 1440px 624px;
  background-position: top center;
  min-height: 624px;
  margin-bottom: 60px;
  padding: 120px 0;
  @media (max-width: 1080px) {
    background-size: 1200px 520px;
    background-position: center;
    padding: 100px 0;
  }
  @media (max-width: 980px) {
    background-size: 1000px 433px;
    background-position: center;
    padding: 90px 0;
  }
  @media (max-width: $mobile-nav) {
    background: black;
    padding: 80px 0;
    min-height: 0px;
  }
  @media (max-width: 600px) {
    padding: 40px 0 60px 0;
  }
  .intro {
    width: 27%;
    @media (max-width: $mobile-nav) {
      width: 40%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
    float: left;
    padding: 8px 0 0 0;
    h1 {
      font-size: 82px;
      word-spacing: 2000px;
      line-height: 74px;
      color: white;
      font-weight: 100;
      em {
        font-style: normal;
        padding-right: 0.1em;
      }
      @media(max-width: $mobile-nav) {
        font-size: 64px;
        line-height: 60px;
      }
      @media(max-width: 600px) {
        font-size: 40px;
        line-height: 44px;
        word-spacing: normal;
        text-transform: uppercase;
        letter-spacing: -1px;
      }
    }

    h2 {
      font-size: 24px;
      color: $bl-sub-black;
      font-family: $secondary-font-family;
      font-weight: 100;
      margin: 50px 0;
      @media(max-width: $mobile-nav) {
        font-size: 20px;
        margin: 10px 0 30px 0;
      }
    }

    img {
      @media(max-width: 600px) {
        margin-bottom: 30px;
      }
    }
  }
  .remap-form {
    width: 27%;
    float: right;
    @media (max-width: $mobile-nav) {
      width: 50%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
    .show-mobile-dropdowns {
      display: block;
      @media(max-width: 600px) {
        padding:10px 0;
        color:#fff;
        text-align:center;
        display: block;
        cursor: pointer;
      }
    }
    .show-dropdown-wrap {
      display: block;
      @media(min-width: 600px) {
        display: flex !important;
        flex-direction:column;
      }
    }
    .select_style {
      margin-bottom: 5px;
      border: none;
      width:100%;
    }
    h1 {
      font-family: $secondary-font-family;
      font-size: 20px;
      color: #FFFFFF;
      margin-bottom: 5px;
    }

    h2 {
      font-family: $standard-font-family;
      font-size: 15px;
      color: #FFFFFF;
      font-weight: 100;
      margin: 11px 0;
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
  #reg_sel {
    background: #F8E01C;
    border: none;
    text-align: center;
    font-family: "webfontregular";
    text-transform: uppercase;
    font-size: 40px;
    padding: 10px 5px;
    margin-bottom: 0;
    text-transform: uppercase;
    @media (max-width: 600px) {
      margin-bottom: 5px;
    }
  }
  button {
    width:100%;
  }
}

.car-details {
  margin: 80px 0 60px 0;
  display: none;
  .inner-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    .car-spec {
      color: white;
      background: repeating-linear-gradient(135deg, #111, #111 20px, #000 20px, #000 40px);
      font-family: $secondary-font-family;
      font-size: 15px;
      text-transform: uppercase;
      padding: 0 0 30px 0;
      width: 25%;
      @media(max-width: 1100px){
        width: 30%;
      }
      @media(max-width: 1000px){
        width: 35%;
      }
      @media(max-width: 660px){
        width: 100%;
        margin-bottom: 40px;
      }
      .car-photo {
        height: 110px;
        width: 110px;
        border-radius: 110px;
        background-size: cover!important;
        background-position: center;
        box-shadow: 0 0 0 5px #f9f9f9, 0 0 0 10px #000000;
        margin: 0 auto;
        position: relative;
        top: -20px;
      }
      h1 {
        text-align: center;
        font-size: 42px;
        line-height: 48px;
        margin-top: 10px;
        font-weight: 100;
      }
      h2 {
        font-size: 22px;
        color: #EBD41A;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        font-weight: 100;
      }
      ul {
        margin-top: 30px;
        li {
          padding: 8px 30px;
          border-bottom: 1px solid #2C2C2C;
          &:nth-child(1) {
            border-top: 1px solid #2C2C2C;
          }
          @media(max-width: 1100px){
            text-align: center;
          }
          @media(max-width: 660px){
            text-align: left;
          }
          span {
            font-family: $standard-font-family;
            color: #838383;
            text-transform: none;
            float: right;
            @media(max-width: 1100px){
              float: none;
              display: block;
              line-height: 16px;
            }
            @media(max-width: 660px){
              float: right;
              display: inline;
              line-height: 20px;
            }
          }
        }
      }
    }

    .map-details {
      border-left: 1px solid $bl-grey-border;
      border-bottom: 1px solid $bl-grey-border;
      background: white;
      width: 75%;
      @media(max-width: 1110px){
        width: 70%;
      }
      @media(max-width: 1000px){
        width: 65%;
      }
      @media(max-width: 740px){
        width: 60%;
      }
      @media(max-width: 660px){
        width: 100%;
      }
      .tabs {
        ul {
          li {
            display: inline-block;
            text-align: center;
            background: $bl-light-grey;
            border-bottom: 1px solid $bl-grey-border;
            border-left: 1px solid $bl-grey-border;
            font-family: $secondary-font-family;
            text-transform: uppercase;
            color: rgba(0,0,0,0.1);
            font-size: 18px;
            line-height: 18px;
            padding: 15px 0 10px 0;
            cursor: pointer;
            width:25%;
            border-top: 1px solid $bl-grey-border;
            &:nth-child(1){
              border-left: none;
            }
            &.active {
              background: white;
              border-bottom: 1px solid white;
              color: black;
            }
            &:last-child {
              border-right: 1px solid $bl-grey-border;
            }
            &.blank {
              border-top: 1px solid $bl-light-grey;
              border-right: 1px solid $bl-light-grey;
              cursor: default;
              &:last-child {
                border-right: 1px solid $bl-light-grey;
              }
            }
          }
        }
      }
      #stage2,
      #stage3,
      #stage4 { display: none; }
      .map-info {
        padding: 0 60px 30px 60px;
        border-right: 1px solid $bl-grey-border;
        @media(max-width: 1000px){
          padding: 0 30px 30px 30px;
        }
        h1 {
          font-size: 30px;
          padding-top: 30px;
          span {
            color: $bl-red;
            font-size: 24px;
            margin-left: 10px;
            span {
              display: inline-block;
              margin-left:0;
            }
          }
        }
        .stage-info {
          font-size: 14px;
          line-height: 16px;
          padding: 30px;
          background: $bl-light-grey;
          margin-top: 30px;
          color: #9C9C9C;
          br {
            display: none;
          }
          a {
            color: $bl-red;
          }
        }
        .bar-container {
          background: $bl-light-grey;
          margin: 5px 0;
          .bar {
            text-align: right;
            padding: 10px;
            font-family: $secondary-font-family;
            text-transform: uppercase;
            &.before {
              background: repeating-linear-gradient(135deg, #F8E01C, #F8E01C 20px, #EBD41A 20px, #EBD41A 40px);
              color: black;
            }
            &.after {
              background: repeating-linear-gradient(135deg, #111, #111 20px, #000 20px, #000 40px);
              color: white;
            }
          }
        }
      }
    }
  }
}

#car-details-empty-state {
  text-align: center;
  margin: 80px 0;
  @media(max-width: 600px) {
    margin: 60px 0;
  }
  .inner-wrap {
    background: white;
    border: 1px solid $bl-grey-border;
    padding: 30px 0;
    h1 {
      font-family: $secondary-font-family;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 18px;
      color: $bl-body;
    }
  }
}

.photo-page {
  margin: 60px 0;
  .text {
    max-width: 750px;
    margin: 85px auto 100px auto;
    @media(max-width: $mobile-nav) {
      margin: 55px auto 70px auto;
    }
    @media(max-width: 600px) {
      margin: 45px auto 50px auto;
    }
    h2 {
      font-family: $primary-font-family;
      font-size: 42px;
      font-weight: 100;
      margin-bottom: 40px;
      @media(max-width: $mobile-nav) {
        font-size: 34px;
        margin-bottom: 35px;
      }
      @media(max-width: 600px) {
        font-size: 26px;
        margin-bottom: 30px;
      }
    }
    p {
      font-size: 18px;
      margin: 20px 0;
      color: $bl-body;
      @media(max-width: $mobile-nav) {
        font-size: 16px;
      }
      @media(max-width: 600px) {
        font-size: 15px;
      }
    }
  }
  .img-row-2 {
    img {
      width: calc(50% - 10px);
      margin-left: 20px;
      margin-bottom: 20px;
      @media(max-width: $mobile-nav) {
        width: calc(50% - 5px);
        margin-left: 10px;
        margin-bottom: 10px;
      }
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .img-row-3 {
    img {
      width: calc(33.33333% - 14px);
      margin-left: 21px;
      margin-bottom: 20px;
      @media(max-width: $mobile-nav) {
        width: calc(33.33333% - 7px);
        margin-left: 10px;
        margin-bottom: 10px;
      }
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
}

.intro-centered {
  max-width: 780px;
  margin: 75px auto 0 auto;
  @media(max-width: $mobile-nav) {
    max-width: 80%;
    margin: 60px auto 0 auto;
  }
  @media(max-width: 600px) {
    max-width: 90%;
    margin: 40px auto 0 auto;
  }
  h1 {
    font-size: 70px;
    text-align: center;
    @media(max-width: $mobile-nav) {
      font-size: 55px;
      line-height: 60px;
      margin-bottom: 20px;
    }
    @media(max-width: 600px) {
      font-size: 40px;
      line-height: 44px;
      word-spacing: normal;
      text-transform: uppercase;
      letter-spacing: -1px;
    }
  }
  p {
    font-size: 25px;
    line-height: 26px;
    text-align: center;
    color: $bl-sub;
    font-family: $secondary-font-family;
    margin-bottom: 60px;
    @media(max-width: $mobile-nav) {
      font-size: 22px;
      line-height: 22px;
    }
    @media(max-width: 600px) {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 40px;
    }
  }
}

#accuair-management {
  margin: 80px auto;
  &.inner-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    .controller {
      width: 442px;
      padding: 0 70px;
      @media(max-width: $mobile-nav) {
        width: 300px;
        padding: 0 50px;
      }
      @media(max-width: 600px) {
        max-width: 340px;
        margin: 40px auto;
      }
      img {
        width: 100%;
      }
    }
    .e-level,.i-level {
      width: calc(50% - 221px);
      @media(max-width: $mobile-nav) {
        width: calc(50% - 150px);
      }
      @media(max-width: 600px) {
        width: 100%;
        text-align: center;
      }
      h1 {
        font-size: 58px;
        margin-bottom: 10px;
        @media(max-width: $mobile-nav) { font-size: 50px; };
        @media(max-width: 600px) { font-size: 38px; };
      }
      p {
        font-size: 16px;
        color: $bl-body;
        @media(max-width: $mobile-nav) { font-size: 14px; line-height: 18px; };
        @media(max-width: 600px) { font-size: 14px; };
      }
    }
    .i-level {
      text-align: right;
      @media(max-width: 600px) {
        text-align: center;
      }
    }
    .apps {
      margin-top: 22px;
      a:nth-child(2) {
        margin-left: 5px;
      }
      img {
        height: 48px;
      }
    }
  }
}

#viair-Compressors {
  margin: 80px auto;
  &.inner-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    .image {
      width: 50%;
      @media(max-width: 600px) {
        max-width: 350px;
        margin: 0 auto 30px auto;
      }
    }
    .text {
      width: 45%;
      margin-left: 5%;
      padding-left: 5%;
      @media(max-width: 600px) {
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        text-align: center;
      }
      h1 {
        font-size: 58px;
        line-height: 58px;
        margin-bottom: 10px;
        @media(max-width: $mobile-nav) { font-size: 50px; line-height: 50px;};
        @media(max-width: 600px) { font-size: 38px; };
      }
      p {
        font-size: 16px;
        color: $bl-body;
        @media(max-width: $mobile-nav) { font-size: 14px; line-height: 18px; };
        @media(max-width: 600px) { font-size: 14px; };
      }
      img {
        height: 48px;
        margin-top: 20px;
      }
    }
  }
}

.wheel-header {
  background-size: cover;
  padding: 180px 0;
  @media(max-width: $mobile-nav) {
    padding: 100px 0;
  }
  @media(max-width: 600px) {
    padding: 60px 0;
  }
  background-position: center;
  img {
    display: block;
    @media(max-width: 600px) {
      height: 60px;
    }
  }
  a {
    display: inline-block;
    margin-top: 30px;
    @media(max-width: 600px) {
      margin-top: 10px;
    }
  }
}

ul.wheels {
  margin: 80px 0;
  @media(max-width: $mobile-nav) {
    margin: 60px 0;
  }
  @media(max-width: 600px) {
    margin: 40px 0;
  }
  li {
    width: 33.333333%;
    display: inline-block;
    text-align: center;
    margin: 30px 0;
    @media(max-width: $mobile-nav) {
      width: 50%;
    }
    h2 {
      font-family: $primary-font-family;
      font-size: 42px;
      margin: 30px 0 10px 0;
      font-weight: 100;
      @media(max-width: 600px) {
        font-size: 30px;
      }
    }
    h3 {
      font-family: $secondary-font-family;
      font-size: 14px;
      text-transform: uppercase;
      color: $bl-body;
      font-weight: 100;
    }
    img {
      width: 300px;
      max-width: 80%;
    }
  }
}


#airrex-management {
  margin: 80px auto;
  &.inner-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    .image {
      width: 60%;
      @media(max-width: 600px) {
        max-width: 350px;
        margin: 0 auto 30px auto;
      }
    }
    .text {
      width: 35%;
      margin-right: 5%;
      @media(max-width: 600px) {
        width: 100%;
        margin-right: 0;
        text-align: center;
      }
      h1 {
        font-size: 58px;
        line-height: 58px;
        margin-bottom: 10px;
        @media(max-width: $mobile-nav) { font-size: 50px; line-height: 50px;};
        @media(max-width: 600px) { font-size: 38px; };
      }
      p {
        font-size: 16px;
        color: $bl-body;
        @media(max-width: $mobile-nav) { font-size: 14px; line-height: 18px; };
        @media(max-width: 600px) { font-size: 14px; };
      }
      img {
        height: 48px;
        margin-top: 20px;
      }
    }
  }
}

.quick-links {
  max-width: 1920px;
  margin: 0 auto;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 10px;
    @media (max-width: 720px) {
      margin-bottom: 2px;
    }
    li {
      width: calc(33.33333333% - 8px);
      background: black;
      text-align: center;
      margin-top: 10px;
      margin-left: 12px;
      position: relative;
      padding: 70px 0;
      @media (max-width: $mobile-nav) {
        padding: 40px 0;
      }
      @media (max-width: 720px) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 2px;
      }
      .btn, h1, p {
        z-index: 2;
        position: relative;
      }
      h1 {
        color: white;
        font-size: 58px;
        line-height: 58px;
        margin-bottom: 10px;
        @media (max-width: $mobile-nav) {
          font-size: 40px;
          line-height: 40px;
        }
      }
      p {
        color: $bl-sub-black;
        font-family: $secondary-font-family;
        font-weight: 100;
        text-transform: uppercase;
        font-size: 22px;
        @media(max-width: $mobile-nav) {
          font-size: 18px;
          margin-bottom: 5px;
        }
        @media(max-width: 720px) {
          display: none;
        }
      }
      &:nth-child(1) {
        width: calc(50% - 5px);
        margin-right: 5px;
        margin-left: 0;
        padding: 100px 0;
        @media (max-width: $mobile-nav) {
          padding: 60px 0;
        }
        @media (max-width: 720px) {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
      .btn {
        margin-top: 10px;
      }
      &:nth-child(2) {
        width: calc(50% - 5px);
        margin-left: 5px;
        padding: 100px 0;
        @media (max-width: $mobile-nav) {
          padding: 60px 0;
        }
        @media (max-width: 720px) {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
      &:nth-child(3) {
        margin-left: 0;
      }
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.4;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      &.link-air:after { background-image: url('../imgs/link-air.jpg'); }
      &.link-tuning:after { background-image: url('../imgs/link-engine.jpg'); }
      &.link-performance:after { background-image: url('../imgs/link-performance.jpg'); }
      &.link-wheels:after { background-image: url('../imgs/link-wheels.jpg'); }
      &.link-lowering:after { background-image: url('../imgs/link-lowering.jpg'); }
    }
  }
}

button.fancybox-button--thumbs { display: none!important; }
