#mobile-nav-items {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 50;
  text-align:left;
  background: $bl-grey;
  overflow: scroll;
  display: none;
  ul {
    margin:10px 0;
  }
  li {
    color:#fff;
    font-weight:700;
    text-transform: uppercase;
    display: block;
    text-align:left;
    // &:nth-child(1) {
    //   display: none;
    // }
    a {
      color:#fff;
      display:block;
      line-height: 1.25em;
      padding:14px 20px;
      border-bottom: 1px solid #2E2E2E;
      &:hover {
        background: black;
        text-decoration: none;
      }
      &.active {

      }
    }
  }
  &.show {
    display: block;
  }
}
