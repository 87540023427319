/*
  Vendors Imports
*/

@import 'bootstrap';
@import 'bootstrap-theme';
@import 'font-awesome';
@import 'jquery.fancybox';
@import 'slick';
@import 'salvattore';
